<template>
  <div class="otp-inputs">
    <template v-for="i in otpLength" >
      <input
        :key="i"
        :id="'otp_' + i"
        type="number"
        @input="otpInput($event, i)"
        @keydown="otpInput_backspace($event, i)"
        @paste="otpInput_paste"
      />
    </template>
  </div>
</template>

<script>
// 123123
export default {
  name: "OTPInput",
  props: {
    'otpLength': {
      default: 6,
      type: Number
    }
  },
  computed: {},
  data() {
    return {
      password: "",
    };
  },
  emits: ["opt_change"],
  methods: {
    otpInput_set_otp(ref) {
      let otp_el = document.getElementById("otp_" + ref);
      if (otp_el.value) {
        otp_el.setAttribute("class", "has-otp");
      } else {
        otp_el.removeAttribute("class");
      }
      let opt_value = "";
      for (let i = 1; i <= this.otpLength; i++) {
        opt_value += document.getElementById("otp_" + i).value;
      }
      if (opt_value.length === this.otpLength) {
        this.password = opt_value;
      } else {
        this.password = "";
      }
      this.$emit("opt_change", this.password);
    },
    otpInput_paste(e) {
      let clipboardData;
      let pastedData;
      e.stopPropagation();
      e.preventDefault();
      clipboardData = e.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData("Text");
      if (pastedData && pastedData.trim().length > 0) {
        pastedData = pastedData.trim();
        let pastedData_split = pastedData.split("");
        let i_el;
        for (let i = 1; i <= this.otpLength; i++) {
          i_el = document.getElementById("otp_" + i);
          i_el.blur();
          if ( pastedData_split[i - 1]) {
            i_el.value = pastedData_split[i - 1];
          }
          if (i_el.value) {
            i_el.setAttribute("class", "has-otp");
          } else {
            i_el.removeAttribute("class");
          }
        }
        this.password = pastedData;
        this.$emit("opt_change", this.password);
      }
    },
    otpInput_backspace(event, ref) {
      if (event.key === "Backspace" || event.key === "Delete") {
        if (ref > 1) {
          let otp_el = document.getElementById("otp_" + ref);
          otp_el.value = "";
          setTimeout(() => {
            document.getElementById("otp_" + (ref - 1)).focus();
            this.otpInput_set_otp(ref);
          }, 30);
        }
      }
    },
    otpInput(event, ref) {
      // test 234678
      event.preventDefault();
      let el = event.target;

      let otp_el = document.getElementById("otp_" + ref);

      let input_val = otp_el.value + "";
      let new_val = input_val.slice(-1);
      el.value = new_val;
      let val = Number(el.value);
      if (isNaN(val)) {
        el.value = "";
      }
      if (otp_el.value !== "") {
        if (document.getElementById("otp_" + (ref + 1))) {
          document.getElementById("otp_" + (ref + 1)).focus();
        } else {
          for (let i = 1; i <= this.otpLength; i++) {
            otp_el.blur();
          }
        }
      }
      this.otpInput_set_otp(ref);
    }
  },
  updated() {},
};
</script>

<style scoped lang="scss">
.otp-inputs {
  display: flex;
  width: 95%;
  gap: 0.5rem;
  padding: 0 0.5rem 1rem;
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    flex: 1;
    width: 20px;
    line-height: 1rem;
    padding: 0.95rem 0.05rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    text-align: center;
    box-sizing: border-box;
    font-weight: bold;
  }

  input.has-otp {
    border: 1px solid #aaa;
  }
}
</style>
