<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bg-white
      "
      :class="{
        'login-signin-on': this.screench == 'signin',
        'login-pass-on': this.screench == 'pass',
        'login-wpass-on': this.screench == 'wpass',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #52529D"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/logos/Rezcomm.png"
              class="max-h-90px"
              alt=""
            />
          </a>
          <!-- <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #986923"
          >
            HUB REZCOMM
          </h3> -->
        </div>
        <div
          class="
            aside-img
            d-flex
            flex-row-fluid
            bgi-no-repeat bgi-position-y-bottom bgi-position-x-center
          "
          style="background-size: 70%;"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Welcome to HUB REZCOMM
                </h3>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Email</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <!-- "spinner", "spinner", "spinner-right" -->
                <button
                  ref="kt_login_signin_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  "
                  :class="`${email_submit ? 'spinner spinner-left ' : ''}`"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Code-->
          <div class="login-form login-wpass">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_wpass_form"
              ref="kt_login_wpass_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Login
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter the code you received
                </p>
              </div>
              <div class="form-group">
                <div>

                <OTPInput v-on:opt_change="opt_change" :otpLength="6"/>
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="hidden"
                    readonly
                    name="code"
                    ref="code"
                    v-model="form.code"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  ref="kt_login_wpass_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  "
                  :class="`${code_submit ? 'spinner spinner-left ' : ''}`"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
          <!--end::Code-->
          <!--begin::Pass-->
          <div class="login-form login-pass">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_pass_form"
              ref="kt_login_pass_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Login
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your password
                </p>
              </div>
              <div class="form-group">
                <div>
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  ref="kt_login_pass_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  "
                  :class="`${password_submit ? 'spinner spinner-left ' : ''}`"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
          <!--end::Code-->
        </div>
        <!--begin::Content footer-->
        <div
          class="
            d-flex
            justify-content-lg-start justify-content-center
            align-items-end
            py-7 py-lg-0
          "
        >
          &copy; Rezcomm - Airport Direct Travel
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss">
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~@/assets/plugins/flaticon/flaticon.css";
@import "~@/assets/plugins/flaticon2/flaticon.css";
@import "~@/assets/plugins/keenthemes-icons/font/ki.css";

@import "~@/assets/sass/style.vue";
@import "~@/assets/customize.scss";
/*
@import "assets/css/style.vue.rtl";*/
</style>
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import OTPInput from "@/components/OTPInput.vue";

import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";
import axios from "axios";
var sha256 = function a(b) {
  function c(a, b) {
    return (a >>> b) | (a << (32 - b));
  }
  for (
    var d,
      e,
      f = Math.pow,
      g = f(2, 32),
      h = "length",
      i = "",
      j = [],
      k = 8 * b[h],
      l = (a.h = a.h || []),
      m = (a.k = a.k || []),
      n = m[h],
      o = {},
      p = 2;
    64 > n;
    p++
  )
    if (!o[p]) {
      for (d = 0; 313 > d; d += p) o[d] = p;
      (l[n] = (f(p, 0.5) * g) | 0), (m[n++] = (f(p, 1 / 3) * g) | 0);
    }
  for (b += "\x80"; (b[h] % 64) - 56; ) b += "\x00";
  for (d = 0; d < b[h]; d++) {
    if (((e = b.charCodeAt(d)), e >> 8)) return;
    j[d >> 2] |= e << (((3 - d) % 4) * 8);
  }
  for (j[j[h]] = (k / g) | 0, j[j[h]] = k, e = 0; e < j[h]; ) {
    var q = j.slice(e, (e += 16)),
      r = l;
    for (l = l.slice(0, 8), d = 0; 64 > d; d++) {
      var s = q[d - 15],
        t = q[d - 2],
        u = l[0],
        v = l[4],
        w =
          l[7] +
          (c(v, 6) ^ c(v, 11) ^ c(v, 25)) +
          ((v & l[5]) ^ (~v & l[6])) +
          m[d] +
          (q[d] =
            16 > d
              ? q[d]
              : (q[d - 16] +
                  (c(s, 7) ^ c(s, 18) ^ (s >>> 3)) +
                  q[d - 7] +
                  (c(t, 17) ^ c(t, 19) ^ (t >>> 10))) |
                0),
        x =
          (c(u, 2) ^ c(u, 13) ^ c(u, 22)) +
          ((u & l[1]) ^ (u & l[2]) ^ (l[1] & l[2]));
      (l = [(w + x) | 0].concat(l)), (l[4] = (l[4] + w) | 0);
    }
    for (d = 0; 8 > d; d++) l[d] = (l[d] + r[d]) | 0;
  }
  for (d = 0; 8 > d; d++)
    for (e = 3; e + 1; e--) {
      var y = (l[d] >> (8 * e)) & 255;
      i += (16 > y ? 0 : "") + y.toString(16);
    }
  return i;
};
var rezusers = ["Alwyn", "Andrew", "Marc"];
var ran = Math.floor(Math.random() * 3);

export default {
  name: "login-1",
  components: {
    OTPInput
  },
  data() {
    return {
      screench: "signin",
      form: {
        email: "",
        password: "",
        code: "",
      },
      email_submit: false,
      password_submit: false,
      code_submit: false,
    };
  },
  computed: {
    backgroundImage() {
      return (
        //process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
        process.env.BASE_URL + "media/logos/Rezcomm_Illustration.svg"
      );
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const wpass_form = KTUtil.getById("kt_login_wpass_form");
    const pass_form = KTUtil.getById("kt_login_pass_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fvpass = formValidation(pass_form, {
      fields: {
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fvwpass = formValidation(wpass_form, {
      fields: {
        code: {
          validators: {
            notEmpty: {
              message: "Code is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      this.email_submit = true;
      var email = this.form.email;
      var varToken_1 = sha256("HUBREZCOMM");
      var sec = rezusers[ran];
      var varToken_2 = sha256(sec);
      var varToken = varToken_1 + "." + varToken_2;
      var data = "email=" + email;
      axios
        .post("https://devhub.rezcomm.com/hubapmydbauth/checkloginn.php", data, {
          headers: { Authorization: "Bearer " + varToken },
        })
        .then((response) => {
          this.codesent = JSON.parse(response.request.response);
          if (this.codesent.user_exists == "Y") {
            if (this.codesent.m6u == "0") {
              if (this.codesent.login_type == "WithPassword") {
                this.showForm("pass");
                this.email_submit = false;
              } else {
                this.showForm("wpass");
                this.email_submit = false;
              }
              localStorage.setItem(
                "x_token",
                JSON.stringify(this.codesent.token)
              );
            } else {
              window.location.href =
                "https://rezcommadm6-dev.auth.eu-west-1.amazoncognito.com/login?client_id=jsoseahvrp94vdb8fglpt6miv&response_type=code&scope=openid&redirect_uri=https://devhub.rezcomm.com/hubapmydbauth/loginaz.php";
            }
			}
		else if(this.codesent.user_exists == "B")
		{
            Swal.fire({
              title: "",
              text: "Your account has been locked for multiple login failure attempts. Please contact the administrator for further information.",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
            this.email_submit = false;		
		}			
			
           else {
            localStorage.removeItem("x_token");
            Swal.fire({
              title: "",
              text: "Please, provide correct data!",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
            this.email_submit = false;
          }
        });
    });

    this.fvpass.on("core.form.valid", () => {
      this.password_submit = true;
      var password = this.form.password;
      var email = this.form.email;
      var data = "email=" + email + "&loginpass=" + password;
      var varToken = localStorage.getItem("x_token");
      axios
        .post(
          "https://devhub.rezcomm.com/hubapmydbauth/actualloginn.php",
          data,
          { headers: { Authorization: "Bearer " + varToken } }
        )
        .then((response) => {
          this.codesent = JSON.parse(response.request.response);
          if (this.codesent.user_exists == "Y") {
            localStorage.setItem(
              "x_token",
              JSON.stringify(this.codesent.token)
            );
            localStorage.setItem(
              "group_id",
              JSON.stringify(this.codesent.group_id)
            );
            localStorage.setItem(
              "brand_id",
              JSON.stringify(this.codesent.brand_id)
            );
            localStorage.setItem(
              "productactivation",
              JSON.stringify(this.codesent.productactivation)
            );
            localStorage.setItem(
              "productsettings",
              JSON.stringify(this.codesent.productsettings)
            );
            localStorage.setItem(
              "languageselected",
              JSON.stringify(this.codesent.languageselected)
            );
            localStorage.setItem(
              "useridlogged",
              JSON.stringify(this.codesent.userid)
            );
            localStorage.setItem(
              "clientidlogged",
              JSON.stringify(this.codesent.clientidlogged)
            );
            localStorage.setItem("emailloggedcurrent", email);
            //this.loadtrans();
            let grpid = JSON.stringify(this.codesent.group_id).replace('"', "");
            if (parseInt(grpid) == 7) {
              window.location.href = "/dashnew.html";
            } else {
              window.location.href = "/dash.html";
            }
          }
		else if(this.codesent.user_exists == "B")
		{
            Swal.fire({
              title: "",
              text: "Your account has been locked for multiple login failure attempts. Please contact the administrator for further information.",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
            this.password_submit = false;		
		}
		  else {
            Swal.fire({
              title: "",
              text: "Invalid Password",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
            this.password_submit = false;
          }
        });
    });

    this.fvwpass.on("core.form.valid", () => {
      this.password_code = true;
      var code = this.form.code;
      var email = this.form.email;
      var data = "email=" + email + "&logincode=" + code;
      var varToken = localStorage.getItem("x_token");
      axios
        .post(
          "https://devhub.rezcomm.com/hubapmydbauth/actuallogincoden.php",
          data,
          { headers: { Authorization: "Bearer " + varToken } }
        )
        .then((response) => {
          this.codesent = JSON.parse(response.request.response);
          if (this.codesent.user_exists == "Y") {
            localStorage.setItem(
              "x_token",
              JSON.stringify(this.codesent.token)
            );
            localStorage.setItem(
              "group_id",
              JSON.stringify(this.codesent.group_id)
            );
            localStorage.setItem(
              "languageselected",
              JSON.stringify(this.codesent.languageselected)
            );
            localStorage.setItem(
              "brand_id",
              JSON.stringify(this.codesent.brand_id)
            );
            localStorage.setItem(
              "productactivation",
              JSON.stringify(this.codesent.productactivation)
            );
            localStorage.setItem(
              "productsettings",
              JSON.stringify(this.codesent.productsettings)
            );
            localStorage.setItem(
              "useridlogged",
              JSON.stringify(this.codesent.userid)
            );
            localStorage.setItem(
              "clientidlogged",
              JSON.stringify(this.codesent.clientidlogged)
            );
            localStorage.setItem("emailloggedcurrent", email);
            //this.loadtrans();
            let grpid = JSON.stringify(this.codesent.group_id).replace('"', "");
            if (parseInt(grpid) == 7) {
              window.location.href = "/dashnew.html";
            } else {
              window.location.href = "/dash.html";
            }
          } else {
            Swal.fire({
              title: "",
              text: "Please, provide correct data!",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
            this.password_code = false;
          }
        });
    });
  },
  methods: {

    opt_change(value) {
      console.log(value);
      this.form.code = value;
    },


    showForm(form) {
      this.screench = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
  },
};
</script>
